import type { Rewrite } from 'next/dist/lib/load-custom-routes'
import type { Locale } from 'next/router'

export const ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX = 'name_asc'
export const ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX = 'name_desc'

// Record<key how it is in algolia, object with details>
export const ALGOLIA_FACETS = {
  specificFieldsOfApplication: {
    facet: 'specificFieldsOfApplication',
    urlParam: 'field-of-application',
    t: 'field-of-application-title',
  },
  areaOfApplication: {
    facet: 'areaOfApplication',
    urlParam: 'area-of-application',
    t: 'area-of-application-title',
  },
  storeAvailability: {
    facet: 'storeAvailability',
    urlParam: 'store-availability',
    t: 'store-availability-title',
  },
  division: {
    facet: 'division',
    urlParam: 'division',
    t: 'division-title',
  },
  category: {
    facet: 'hierarchicalCategories.lvl0',
    urlParam: '',
    t: 'product-categories-title',
  },
  family: {
    facet: 'hierarchicalCategories.lvl2',
    urlParam: 'product-family',
    t: 'family-title',
  },
  type: {
    facet: 'hierarchicalCategories.lvl1',
    urlParam: 'product-type',
    t: 'type-title',
  },
  productFamilyDescFil: {
    facet: 'typeFilters.productFamilyDescFil',
    urlParam: 'product-family-desc',
    t: 'productFamilyDescFil',
  },
  boardPropertiesDesc: {
    facet: 'typeFilters.boardPropertiesDesc',
    urlParam: 'board-properties',
    t: 'boardPropertiesDesc',
  },
  reactionToFireTd: {
    facet: 'typeFilters.reactionToFireTd',
    urlParam: 'reaction-to-fire',
    t: 'reactionToFireTd',
  },
  nominalThicknessMetTd: {
    facet: 'typeFilters.nominalThicknessMetTd',
    urlParam: 'nominal-thickness',
    t: 'nominalThicknessMetTd',
  },
  primerAndPaintTypeDesc: {
    facet: 'typeFilters.primerAndPaintTypeDesc',
    urlParam: 'primer-and-paint-type',
    t: 'primerAndPaintTypeDesc',
  },
  substrateDesc: {
    facet: 'typeFilters.substrateDesc',
    urlParam: 'substrate',
    t: 'substrateDesc',
  },
  thermalConductivityLevelTd: {
    facet: 'typeFilters.thermalConductivityLevelTd',
    urlParam: 'thermal-conductivity-level',
    t: 'thermalConductivityLevelTd',
  },
  installationTypeTd: {
    facet: 'typeFilters.installationTypeTd',
    urlParam: 'installation-type',
    t: 'installationTypeTd',
  },
  suspensionHangerAndConnectorTypeDesc: {
    facet: 'typeFilters.suspensionHangerAndConnectorTypeDesc',
    urlParam: 'suspension-hanger-and-connector-type',
    t: 'suspensionHangerAndConnectorTypeDesc',
  },
  loadCapacityClassTd: {
    facet: 'typeFilters.loadCapacityClassTd',
    urlParam: 'load-capacity-class',
    t: 'loadCapacityClassTd',
  },
  corrosionClassTd: {
    facet: 'typeFilters.corrosionClassTd',
    urlParam: 'corrosion-class',
    t: 'corrosionClassTd',
  },
  hangingHeightMinMaxMetTd: {
    facet: 'typeFilters.hangingHeightMinMaxMetTd',
    urlParam: 'hanging-height-min-max',
    t: 'hangingHeightMinMaxMetTd',
  },
  anchorSubstrateDesc: {
    facet: 'typeFilters.anchorSubstrateDesc',
    urlParam: 'anchor-substrate',
    t: 'anchorSubstrateDesc',
  },
  dowelAndAnchorTypeDesc: {
    facet: 'typeFilters.dowelAndAnchorTypeDesc',
    urlParam: 'dowel-and-anchor-type',
    t: 'dowelAndAnchorTypeDesc',
  },
  suitableForBoardThicknessMetTd: {
    facet: 'typeFilters.suitableForBoardThicknessMetTd',
    urlParam: 'suitable-for-board-thickness',
    t: 'suitableForBoardThicknessMetTd',
  },
  windowElementTypeDesc: {
    facet: 'typeFilters.windowElementTypeDesc',
    urlParam: 'window-element-type',
    t: 'windowElementTypeDesc',
  },
  doorElementTypeDesc: {
    facet: 'typeFilters.doorElementTypeDesc',
    urlParam: 'door-element-type',
    t: 'doorElementTypeDesc',
  },
  tapeAndStripTypeDesc: {
    facet: 'typeFilters.tapeAndStripTypeDesc',
    urlParam: 'tape-and-strip-type',
    t: 'tapeAndStripTypeDesc',
  },
  plasterTypeDesc: {
    facet: 'typeFilters.plasterTypeDesc',
    urlParam: 'plaster-type',
    t: 'plasterTypeDesc',
  },
  materialTd: {
    facet: 'typeFilters.materialTd',
    urlParam: 'material',
    t: 'materialTd',
  },
  applicationMethodDesc: {
    facet: 'typeFilters.applicationMethodDesc',
    urlParam: 'application-method',
    t: 'applicationMethodDesc',
  },
  grainSizeMetTd: {
    facet: 'typeFilters.grainSizeMetTd',
    urlParam: 'grain-size',
    t: 'grainSizeMetTd',
  },
  glueAndSealerTypeDesc: {
    facet: 'typeFilters.glueAndSealerTypeDesc',
    urlParam: 'glue-and-sealer-type',
    t: 'glueAndSealerTypeDesc',
  },
  angleTypeDesc: {
    facet: 'typeFilters.angleTypeDesc',
    urlParam: 'angle-type',
    t: 'angleTypeDesc',
  },
  profileTypeDesc: {
    facet: 'typeFilters.profileTypeDesc',
    urlParam: 'profile-type',
    t: 'profileTypeDesc',
  },
  profileTypeDrywallingDesc: {
    facet: 'typeFilters.profileTypeDrywallingDesc',
    urlParam: 'profile-type-drywalling',
    t: 'profileTypeDrywallingDesc',
  },
  lapHeightMetTd: {
    facet: 'typeFilters.lapHeightMetTd',
    urlParam: 'lap-height',
    t: 'lapHeightMetTd',
  },
  corrosivityCategoryTd: {
    facet: 'typeFilters.corrosivityCategoryTd',
    urlParam: 'corrosivity-category',
    t: 'corrosivityCategoryTd',
  },
  applicationPlasterMortarDesc: {
    facet: 'typeFilters.applicationPlasterMortarDesc',
    urlParam: 'application-plaster-mortar',
    t: 'applicationPlasterMortarDesc',
  },
  installedIntoComponentAccessPanelsDesc: {
    facet: 'typeFilters.installedIntoComponentAccessPanelsDesc',
    urlParam: 'installed-into-component-access-panels',
    t: 'installedIntoComponentAccessPanelsDesc',
  },
  dimensionLongitudinalAccessPanelsDescTd: {
    facet: 'typeFilters.dimensionLongitudinalAccessPanelsDescTd',
    urlParam: 'dimension-longitudinal-access-panels',
    t: 'dimensionLongitudinalAccessPanelsDescTd',
  },
  dimensionLateralAccessPanelsDescTd: {
    facet: 'typeFilters.dimensionLateralAccessPanelsDescTd',
    urlParam: 'dimensionLateral-access-panels',
    t: 'dimensionLateralAccessPanelsDescTd',
  },
  standardFeaturesAccessPanelsDesc: {
    facet: 'typeFilters.standardFeaturesAccessPanelsDesc',
    urlParam: 'standard-features-access',
    t: 'standardFeaturesAccessPanelsDesc',
  },
  optionalFeaturesAccessPanelsDesc: {
    facet: 'typeFilters.optionalFeaturesAccessPanelsDesc',
    urlParam: 'optional-features-access',
    t: 'optionalFeaturesAccessPanelsDesc',
  },
  plankingThicknessMetTd: {
    facet: 'typeFilters.plankingThicknessMetTd',
    urlParam: 'planking-thickness',
    t: 'plankingThicknessMetTd',
  },
  fireResistanceDurationAccessPanelsTd: {
    facet: 'typeFilters.fireResistanceDurationAccessPanelsTd',
    urlParam: 'fire-resistance-duration-access',
    t: 'fireResistanceDurationAccessPanelsTd',
  },
  screwAndNutPropertiesDesc: {
    facet: 'typeFilters.screwAndNutPropertiesDesc',
    urlParam: 'screw-and-nut-properties',
    t: 'screwAndNutPropertiesDesc',
  },
  screwTypeDesc: {
    facet: 'typeFilters.screwTypeDesc',
    urlParam: 'screw-type',
    t: 'screwTypeDesc',
  },
  protectiveTreatmentDesc: {
    facet: 'typeFilters.protectiveTreatmentDesc',
    urlParam: 'protective-treatment',
    t: 'protectiveTreatmentDesc',
  },
  screwDriveDesc: {
    facet: 'typeFilters.screwDriveDesc',
    urlParam: 'screw-drive',
    t: 'screwDriveDesc',
  },
  threadTypeDesc: {
    facet: 'typeFilters.threadTypeDesc',
    urlParam: 'thread-type',
    t: 'threadTypeDesc',
  },
  applicationAreaFillingMaterialsDesc: {
    facet: 'typeFilters.applicationAreaFillingMaterialsDesc',
    urlParam: 'application-area-filling-materials',
    t: 'applicationAreaFillingMaterialsDesc',
  },
  physicalStateOfMixtureDesc: {
    facet: 'typeFilters.physicalStateOfMixtureDesc',
    urlParam: 'physical-state-of-mixture',
    t: 'physicalStateOfMixtureDesc',
  },
  layerThicknessMinMaxMetTd: {
    facet: 'typeFilters.layerThicknessMinMaxMetTd',
    urlParam: 'layer-thickness-min-max',
    t: 'layerThicknessMinMaxMetTd',
  },
  toolTypeDesc: {
    facet: 'typeFilters.toolTypeDesc',
    urlParam: 'tool-type',
    t: 'toolTypeDesc',
  },
  lengthMetTd: {
    facet: 'typeFilters.lengthMetTd',
    urlParam: 'length',
    t: 'lengthMetTd',
  },
  widthMetTd: {
    facet: 'typeFilters.widthMetTd',
    urlParam: 'width',
    t: 'widthMetTd',
  },
  edgeTypeLongEdgeAbbreviationTd: {
    facet: 'typeFilters.edgeTypeLongEdgeAbbreviationTd',
    urlParam: 'long-edge-type',
    t: 'edgeTypeLongEdgeAbbreviationTd',
  },
  edgeTypeFrontEdgeAbbreviationTd: {
    facet: 'typeFilters.edgeTypeFrontEdgeAbbreviationTd',
    urlParam: 'front-edge-type',
    t: 'edgeTypeFrontEdgeAbbreviationTd',
  },
  thicknessMetTd: {
    facet: 'typeFilters.thicknessMetTd',
    urlParam: 'thickness',
    t: 'thicknessMetTd',
  },
  perforationTypeTd: {
    facet: 'typeFilters.perforationTypeTd',
    urlParam: 'perforation-type',
    t: 'perforationTypeTd',
  },
  perforationPatternFrontSideTd: {
    facet: 'typeFilters.perforationPatternFrontSideTd',
    urlParam: 'perforation-pattern-front-side',
    t: 'perforationPatternFrontSideTd',
  },
  sealingAndFoilTypeDesc: {
    facet: 'typeFilters.sealingAndFoilTypeDesc',
    urlParam: 'sealing-and-foil-type',
    t: 'sealingAndFoilTypeDesc',
  },
  productBrandDescFil: {
    facet: 'typeFilters.productBrandDescFil',
    urlParam: 'brand',
    t: 'productBrandDescFil',
  },
  thermalResistanceDeclaredMetTd: {
    facet: 'typeFilters.thermalResistanceDeclaredMetTd',
    urlParam: 'rd-value',
    t: 'thermalResistanceDeclaredMetTd',
  },
  thermalResistanceMetTd: {
    facet: 'typeFilters.thermalResistanceMetTd',
    t: 'thermalResistanceMetTd',
    urlParam: 'r-value',
  },
} as const

// TODO: check how to use only ALGOLIA_FACETS_MAP without the need of ALGOLIA_FACETS
export const ALGOLIA_FACETS_MAP = {
  hierarchicalMenu: {
    // category
    [ALGOLIA_FACETS.category.facet]: {
      t: ALGOLIA_FACETS.category.t,
      urlParam: ALGOLIA_FACETS.category.urlParam,
    },
    // product type
    [ALGOLIA_FACETS.type.facet]: {
      t: ALGOLIA_FACETS.type.t,
      urlParam: ALGOLIA_FACETS.type.urlParam,
    },
    // product family
    [ALGOLIA_FACETS.family.facet]: {
      t: ALGOLIA_FACETS.family.t,
      urlParam: ALGOLIA_FACETS.family.urlParam,
    },
  },
  // Property 'order' gives the facet an ordering priority over the alphabetical ordering of the translated key
  refinementList: {
    [ALGOLIA_FACETS.specificFieldsOfApplication.facet]: {
      t: ALGOLIA_FACETS.specificFieldsOfApplication.t,
      urlParam: ALGOLIA_FACETS.specificFieldsOfApplication.urlParam,
      order: 1,
      displayOnAllProductsPage: true,
    },
    [ALGOLIA_FACETS.areaOfApplication.facet]: {
      t: ALGOLIA_FACETS.areaOfApplication.t,
      urlParam: ALGOLIA_FACETS.areaOfApplication.urlParam,
      order: 2,
      displayOnAllProductsPage: true,
    },
    [ALGOLIA_FACETS.division.facet]: {
      t: ALGOLIA_FACETS.division.t,
      urlParam: ALGOLIA_FACETS.division.urlParam,
      order: 3,
      displayOnAllProductsPage: true,
    },
    [ALGOLIA_FACETS.storeAvailability.facet]: {
      t: ALGOLIA_FACETS.storeAvailability.t,
      urlParam: ALGOLIA_FACETS.storeAvailability.urlParam,
      includedLocales: ['fr-BE', 'nl-BE', 'de-DE'] as unknown as Locale[],
      order: 4,
      displayOnAllProductsPage: true,
    },
    [ALGOLIA_FACETS.productFamilyDescFil.facet]: {
      t: ALGOLIA_FACETS.productFamilyDescFil.t,
      urlParam: ALGOLIA_FACETS.productFamilyDescFil.urlParam,
    },
    [ALGOLIA_FACETS.boardPropertiesDesc.facet]: {
      t: ALGOLIA_FACETS.boardPropertiesDesc.t,
      urlParam: ALGOLIA_FACETS.boardPropertiesDesc.urlParam,
    },
    [ALGOLIA_FACETS.primerAndPaintTypeDesc.facet]: {
      t: ALGOLIA_FACETS.primerAndPaintTypeDesc.t,
      urlParam: ALGOLIA_FACETS.primerAndPaintTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.thermalConductivityLevelTd.facet]: {
      t: ALGOLIA_FACETS.thermalConductivityLevelTd.t,
      urlParam: ALGOLIA_FACETS.thermalConductivityLevelTd.urlParam,
    },
    [ALGOLIA_FACETS.installationTypeTd.facet]: {
      t: ALGOLIA_FACETS.installationTypeTd.t,
      urlParam: ALGOLIA_FACETS.installationTypeTd.urlParam,
    },
    [ALGOLIA_FACETS.suspensionHangerAndConnectorTypeDesc.facet]: {
      t: ALGOLIA_FACETS.suspensionHangerAndConnectorTypeDesc.t,
      urlParam: ALGOLIA_FACETS.suspensionHangerAndConnectorTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.dowelAndAnchorTypeDesc.facet]: {
      t: ALGOLIA_FACETS.dowelAndAnchorTypeDesc.t,
      urlParam: ALGOLIA_FACETS.dowelAndAnchorTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.windowElementTypeDesc.facet]: {
      t: ALGOLIA_FACETS.windowElementTypeDesc.t,
      urlParam: ALGOLIA_FACETS.windowElementTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.doorElementTypeDesc.facet]: {
      t: ALGOLIA_FACETS.doorElementTypeDesc.t,
      urlParam: ALGOLIA_FACETS.doorElementTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.tapeAndStripTypeDesc.facet]: {
      t: ALGOLIA_FACETS.tapeAndStripTypeDesc.t,
      urlParam: ALGOLIA_FACETS.tapeAndStripTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.plasterTypeDesc.facet]: {
      t: ALGOLIA_FACETS.plasterTypeDesc.t,
      urlParam: ALGOLIA_FACETS.plasterTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.materialTd.facet]: {
      t: ALGOLIA_FACETS.materialTd.t,
      urlParam: ALGOLIA_FACETS.materialTd.urlParam,
    },
    [ALGOLIA_FACETS.glueAndSealerTypeDesc.facet]: {
      t: ALGOLIA_FACETS.glueAndSealerTypeDesc.t,
      urlParam: ALGOLIA_FACETS.glueAndSealerTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.angleTypeDesc.facet]: {
      t: ALGOLIA_FACETS.angleTypeDesc.t,
      urlParam: ALGOLIA_FACETS.angleTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.profileTypeDesc.facet]: {
      t: ALGOLIA_FACETS.profileTypeDesc.t,
      urlParam: ALGOLIA_FACETS.profileTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.profileTypeDrywallingDesc.facet]: {
      t: ALGOLIA_FACETS.profileTypeDrywallingDesc.t,
      urlParam: ALGOLIA_FACETS.profileTypeDrywallingDesc.urlParam,
    },
    [ALGOLIA_FACETS.applicationPlasterMortarDesc.facet]: {
      t: ALGOLIA_FACETS.applicationPlasterMortarDesc.t,
      urlParam: ALGOLIA_FACETS.applicationPlasterMortarDesc.urlParam,
    },
    [ALGOLIA_FACETS.installedIntoComponentAccessPanelsDesc.facet]: {
      t: ALGOLIA_FACETS.installedIntoComponentAccessPanelsDesc.t,
      urlParam: ALGOLIA_FACETS.installedIntoComponentAccessPanelsDesc.urlParam,
    },
    [ALGOLIA_FACETS.dimensionLongitudinalAccessPanelsDescTd.facet]: {
      t: ALGOLIA_FACETS.dimensionLongitudinalAccessPanelsDescTd.t,
      urlParam: ALGOLIA_FACETS.dimensionLongitudinalAccessPanelsDescTd.urlParam,
    },
    [ALGOLIA_FACETS.dimensionLateralAccessPanelsDescTd.facet]: {
      t: ALGOLIA_FACETS.dimensionLateralAccessPanelsDescTd.t,
      urlParam: ALGOLIA_FACETS.dimensionLateralAccessPanelsDescTd.urlParam,
    },
    [ALGOLIA_FACETS.standardFeaturesAccessPanelsDesc.facet]: {
      t: ALGOLIA_FACETS.standardFeaturesAccessPanelsDesc.t,
      urlParam: ALGOLIA_FACETS.standardFeaturesAccessPanelsDesc.urlParam,
    },
    [ALGOLIA_FACETS.optionalFeaturesAccessPanelsDesc.facet]: {
      t: ALGOLIA_FACETS.optionalFeaturesAccessPanelsDesc.t,
      urlParam: ALGOLIA_FACETS.optionalFeaturesAccessPanelsDesc.urlParam,
    },
    [ALGOLIA_FACETS.screwAndNutPropertiesDesc.facet]: {
      t: ALGOLIA_FACETS.screwAndNutPropertiesDesc.t,
      urlParam: ALGOLIA_FACETS.screwAndNutPropertiesDesc.urlParam,
    },
    [ALGOLIA_FACETS.toolTypeDesc.facet]: {
      t: ALGOLIA_FACETS.toolTypeDesc.t,
      urlParam: ALGOLIA_FACETS.toolTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.reactionToFireTd.facet]: {
      t: ALGOLIA_FACETS.reactionToFireTd.t,
      urlParam: ALGOLIA_FACETS.reactionToFireTd.urlParam,
    },
    [ALGOLIA_FACETS.nominalThicknessMetTd.facet]: {
      t: ALGOLIA_FACETS.nominalThicknessMetTd.t,
      urlParam: ALGOLIA_FACETS.nominalThicknessMetTd.urlParam,
    },
    [ALGOLIA_FACETS.loadCapacityClassTd.facet]: {
      t: ALGOLIA_FACETS.loadCapacityClassTd.t,
      urlParam: ALGOLIA_FACETS.loadCapacityClassTd.urlParam,
    },
    [ALGOLIA_FACETS.corrosionClassTd.facet]: {
      t: ALGOLIA_FACETS.corrosionClassTd.t,
      urlParam: ALGOLIA_FACETS.corrosionClassTd.urlParam,
    },
    [ALGOLIA_FACETS.corrosivityCategoryTd.facet]: {
      t: ALGOLIA_FACETS.corrosivityCategoryTd.t,
      urlParam: ALGOLIA_FACETS.corrosivityCategoryTd.urlParam,
    },
    [ALGOLIA_FACETS.hangingHeightMinMaxMetTd.facet]: {
      t: ALGOLIA_FACETS.hangingHeightMinMaxMetTd.t,
      urlParam: ALGOLIA_FACETS.hangingHeightMinMaxMetTd.urlParam,
    },
    [ALGOLIA_FACETS.anchorSubstrateDesc.facet]: {
      t: ALGOLIA_FACETS.anchorSubstrateDesc.t,
      urlParam: ALGOLIA_FACETS.anchorSubstrateDesc.urlParam,
    },
    [ALGOLIA_FACETS.substrateDesc.facet]: {
      t: ALGOLIA_FACETS.substrateDesc.t,
      urlParam: ALGOLIA_FACETS.substrateDesc.urlParam,
    },
    [ALGOLIA_FACETS.applicationMethodDesc.facet]: {
      t: ALGOLIA_FACETS.applicationMethodDesc.t,
      urlParam: ALGOLIA_FACETS.applicationMethodDesc.urlParam,
    },
    [ALGOLIA_FACETS.layerThicknessMinMaxMetTd.facet]: {
      t: ALGOLIA_FACETS.layerThicknessMinMaxMetTd.t,
      urlParam: ALGOLIA_FACETS.layerThicknessMinMaxMetTd.urlParam,
    },
    [ALGOLIA_FACETS.grainSizeMetTd.facet]: {
      t: ALGOLIA_FACETS.grainSizeMetTd.t,
      urlParam: ALGOLIA_FACETS.grainSizeMetTd.urlParam,
    },
    [ALGOLIA_FACETS.lapHeightMetTd.facet]: {
      t: ALGOLIA_FACETS.lapHeightMetTd.t,
      urlParam: ALGOLIA_FACETS.lapHeightMetTd.urlParam,
    },
    [ALGOLIA_FACETS.fireResistanceDurationAccessPanelsTd.facet]: {
      t: ALGOLIA_FACETS.fireResistanceDurationAccessPanelsTd.t,
      urlParam: ALGOLIA_FACETS.fireResistanceDurationAccessPanelsTd.urlParam,
    },
    [ALGOLIA_FACETS.plankingThicknessMetTd.facet]: {
      t: ALGOLIA_FACETS.plankingThicknessMetTd.t,
      urlParam: ALGOLIA_FACETS.plankingThicknessMetTd.urlParam,
    },
    [ALGOLIA_FACETS.protectiveTreatmentDesc.facet]: {
      t: ALGOLIA_FACETS.protectiveTreatmentDesc.t,
      urlParam: ALGOLIA_FACETS.protectiveTreatmentDesc.urlParam,
    },
    [ALGOLIA_FACETS.suitableForBoardThicknessMetTd.facet]: {
      t: ALGOLIA_FACETS.suitableForBoardThicknessMetTd.t,
      urlParam: ALGOLIA_FACETS.suitableForBoardThicknessMetTd.urlParam,
    },
    [ALGOLIA_FACETS.screwDriveDesc.facet]: {
      t: ALGOLIA_FACETS.screwDriveDesc.t,
      urlParam: ALGOLIA_FACETS.screwDriveDesc.urlParam,
    },
    [ALGOLIA_FACETS.screwTypeDesc.facet]: {
      t: ALGOLIA_FACETS.screwTypeDesc.t,
      urlParam: ALGOLIA_FACETS.screwTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.threadTypeDesc.facet]: {
      t: ALGOLIA_FACETS.threadTypeDesc.t,
      urlParam: ALGOLIA_FACETS.threadTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.applicationAreaFillingMaterialsDesc.facet]: {
      t: ALGOLIA_FACETS.applicationAreaFillingMaterialsDesc.t,
      urlParam: ALGOLIA_FACETS.applicationAreaFillingMaterialsDesc.urlParam,
    },
    [ALGOLIA_FACETS.physicalStateOfMixtureDesc.facet]: {
      t: ALGOLIA_FACETS.physicalStateOfMixtureDesc.t,
      urlParam: ALGOLIA_FACETS.physicalStateOfMixtureDesc.urlParam,
    },
    [ALGOLIA_FACETS.lengthMetTd.facet]: {
      t: ALGOLIA_FACETS.lengthMetTd.t,
      urlParam: ALGOLIA_FACETS.lengthMetTd.urlParam,
    },
    [ALGOLIA_FACETS.widthMetTd.facet]: {
      t: ALGOLIA_FACETS.widthMetTd.t,
      urlParam: ALGOLIA_FACETS.widthMetTd.urlParam,
    },
    [ALGOLIA_FACETS.edgeTypeLongEdgeAbbreviationTd.facet]: {
      t: ALGOLIA_FACETS.edgeTypeLongEdgeAbbreviationTd.t,
      urlParam: ALGOLIA_FACETS.edgeTypeLongEdgeAbbreviationTd.urlParam,
    },
    [ALGOLIA_FACETS.edgeTypeFrontEdgeAbbreviationTd.facet]: {
      t: ALGOLIA_FACETS.edgeTypeFrontEdgeAbbreviationTd.t,
      urlParam: ALGOLIA_FACETS.edgeTypeFrontEdgeAbbreviationTd.urlParam,
    },
    [ALGOLIA_FACETS.thicknessMetTd.facet]: {
      t: ALGOLIA_FACETS.thicknessMetTd.t,
      urlParam: ALGOLIA_FACETS.thicknessMetTd.urlParam,
    },
    [ALGOLIA_FACETS.perforationTypeTd.facet]: {
      t: ALGOLIA_FACETS.perforationTypeTd.t,
      urlParam: ALGOLIA_FACETS.perforationTypeTd.urlParam,
    },
    [ALGOLIA_FACETS.perforationPatternFrontSideTd.facet]: {
      t: ALGOLIA_FACETS.perforationPatternFrontSideTd.t,
      urlParam: ALGOLIA_FACETS.perforationPatternFrontSideTd.urlParam,
    },
    [ALGOLIA_FACETS.sealingAndFoilTypeDesc.facet]: {
      t: ALGOLIA_FACETS.sealingAndFoilTypeDesc.t,
      urlParam: ALGOLIA_FACETS.sealingAndFoilTypeDesc.urlParam,
    },
    [ALGOLIA_FACETS.productBrandDescFil.facet]: {
      t: ALGOLIA_FACETS.productBrandDescFil.t,
      urlParam: ALGOLIA_FACETS.productBrandDescFil.urlParam,
    },
    [ALGOLIA_FACETS.thermalResistanceDeclaredMetTd.facet]: {
      t: ALGOLIA_FACETS.thermalResistanceDeclaredMetTd.t,
      urlParam: ALGOLIA_FACETS.thermalResistanceDeclaredMetTd.urlParam,
    },
  },
} as const

export const SEARCH_QUERY_KEY = 'search' as const
export const SORT_KEY = 'order' as const

export type FacetKeyExceptCategory = Exclude<keyof typeof ALGOLIA_FACETS, 'category'>
export type UrlParam = (typeof ALGOLIA_FACETS)[FacetKeyExceptCategory]['urlParam']
export type Facet = (typeof ALGOLIA_FACETS)[FacetKeyExceptCategory]['facet']
export type RefinementListKeys = keyof (typeof ALGOLIA_FACETS_MAP)['refinementList']
export type RefinementListQueryParams =
  (typeof ALGOLIA_FACETS_MAP)['refinementList'][RefinementListKeys]['urlParam']

// all the facets, except categoryId, which is a slug (not a query param)
export const ALGOLIA_QUERY_PARAMS = Object.keys(ALGOLIA_FACETS)
  .filter((key) => key !== 'category')
  .map((key) => ALGOLIA_FACETS[key as keyof typeof ALGOLIA_FACETS].urlParam) as UrlParam[]

export const HIERARCHICAL_LEVELS_SEPARATOR = ' > '

export const HIERARCHICAL_CATEGORIES = [
  ALGOLIA_FACETS.category.facet,
  ALGOLIA_FACETS.type.facet,
  ALGOLIA_FACETS.family.facet,
]

export const TYPE_FILTERS_PREFIX = 'typeFilters' as const

export type QueryParams = Partial<
  // refinement list (mulstiselect)
  Record<RefinementListQueryParams, string[]> & {
    // search query and sort by
    [SEARCH_QUERY_KEY]: string
    [SORT_KEY]: string
  } & {
    // special case, type and family are part of the hierarchical menu
    // NOTE: it's hardcoded because of the TS error when the value if computed from other
    [ALGOLIA_FACETS.type.urlParam]: string
    [ALGOLIA_FACETS.family.urlParam]: string
  }
>

export const nextJSMissingRewriteConfigRouteFromAlgoliaQueries: Required<Rewrite['missing']> = [
  ...Object.values(ALGOLIA_FACETS_MAP.refinementList).map((value) => ({
    type: 'query' as const,
    // we know that algolia always adds '[0]' for refinement list selecte options in the url
    key: `${value.urlParam}[0]`,
  })),
  // we should also add product-type, as we treat it as a query param, even it's part of the hierarchical menu
  {
    type: 'query',
    key: ALGOLIA_FACETS.type.urlParam,
  },
  // we should also add search query
  {
    type: 'query',
    key: SEARCH_QUERY_KEY,
  },
  // and sort query
  {
    type: 'query',
    key: SORT_KEY,
  },
]
