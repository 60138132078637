import type { UseTranslationOptions } from 'react-i18next'
import { useTranslation } from 'next-i18next'

import type { KeyPrefix } from 'i18next'

export function useT<TKPrefix extends KeyPrefix<'pcat'> = undefined>(
  options?: UseTranslationOptions<TKPrefix>,
) {
  const { t } = useTranslation('pcat', options)

  return { t }
}
